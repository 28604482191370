import { Grid } from '@mui/material'
import { type PageProps } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { useSessionStorage } from 'react-use'
import { Layout } from '../../components/Layout'
import { MarriageRings } from '../../components/MarriageRings'
import { SEO } from '../../components/SEO'
import { useMarriageRingParams } from '../../hooks/useMarriageRingParams'

export const Head = () => (
    <SEO
        title="安い結婚指輪・激安のマリッジリングなら低価格で高品質なミスプラチナ"
        description="低価格の結婚指輪はメーカー直販ミスプラチナがおすすめ。プラチナのペアリング一覧。"
        page="marriagering"
    />
)

const MarriageRingPage: React.FC<PageProps> = ({ location }) => {
    const params = useMarriageRingParams(location.search)
    const [isFirstVisit, setIsFirstVisit] = useSessionStorage('fv', true)
    React.useEffect(() => {
        return () => setIsFirstVisit(false)
    })
    return (
        <Layout breadcrumbs={[{ name: '安い結婚指輪・マリッジリング' }]}>
            {isFirstVisit && (
                <Grid container spacing={0} component="aside">
                    <Grid item sm={3} />
                    <Grid item sm={6} xs={12}>
                        <StaticImage
                            src="../../images/marriagering/marriage.jpg"
                            alt=""
                        />
                    </Grid>
                </Grid>
            )}
            <MarriageRings {...params} />
            <Grid container spacing={0} component="aside" sx={{ mt: 2 }}>
                <Grid item sm={3} />
                <Grid item sm={6} xs={12}>
                    <a href="https://missplatinum.jp/mydesign">
                        <StaticImage
                            src="../../images/marriagering/order.jpg"
                            alt=""
                        />
                    </a>
                </Grid>
            </Grid>
        </Layout>
    )
}

export default MarriageRingPage
